export default {
  toolbar: {
    undo: 'Undo',
    redo: 'Redo',
    paintformat: 'Paint format',
    clearformat: 'Clear format',
    format: 'Format',
    font: 'Font',
    fontSize: 'Font size',
    fontBold: 'Font bold',
    fontItalic: 'Font italic',
    underline: 'Underline',
    strike: 'Strike',
    textColor: 'Text color',
    fillColor: 'Fill color',
    border: 'Borders',
    merge: 'Merge cells',
    align: 'Horizontal align',
    valign: 'Vertical align',
    textwrap: 'Text wrapping',
    freeze: 'Freeze cell',
    formula: 'Functions',
    more: 'More',
  },
  contextmenu: {
    copy: 'Copy',
    cut: 'Cut',
    paste: 'Paste',
    pasteValue: 'Paste values only',
    pasteFormat: 'Paste format only',
    insertRow: 'Insert row',
    insertColumn: 'Insert column',
    deleteRow: 'Delete row',
    deleteColumn: 'Delete column',
    deleteCell: 'Delete cell',
    deleteCellText: 'Delete cell text',
  },
  format: {
    normal: 'Normal',
    text: 'Plain Text',
    number: 'Number',
    percent: 'Percent',
    rmb: 'RMB',
    usd: 'USD',
    date: 'Date',
    time: 'Time',
    datetime: 'Date time',
    duration: 'Duration',
  },
  formula: {
    sum: 'Sum',
    average: 'Average',
    max: 'Max',
    min: 'Min',
    concat: 'Concat',
  },
};
